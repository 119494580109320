import { useState, useEffect } from 'react';

/**
 * Determines if the native browser document|window is available and ready to be
 * accessed. This check is useful for components that depend on native document
 * or window objects and must postpone calls to those references when components
 * are rendered server-side. Consumers can simply create an effect that depends
 * on this hooks ready state.
 *
 * @return {boolean} isReady True when native browser document or window objects
 *  exist and are ready to be accessed.
 */
export default function useIsDocumentReady() {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(typeof document !== 'undefined');
  }, []);

  return isReady;
}
