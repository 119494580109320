import React from 'react';

import useClassy from '@core/hooks/useClassy';

import classes from './style.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  heading: React.ReactNode;
  shift?: string;
}

const APISectionHeader = ({ children, className, heading, ...props }: Props) => {
  const bem = useClassy(classes, 'APISectionHeader');

  if (!heading) {
    return null;
  }

  return (
    <header className={bem('&', 'rm-APISectionHeader', className)} {...props}>
      <div className={bem('-heading')}>{heading}</div>
      {children}
    </header>
  );
};

export default React.memo(APISectionHeader);
