import type { FC } from 'react';

import { formatDistance, format, isToday, isYesterday } from 'date-fns';
import React from 'react';

import Tooltip from '@ui/Tooltip';

import './style.scss';

interface DateLineProps extends React.HTMLAttributes<HTMLElement> {
  absoluteDates?: boolean;
  /**
   * Matches date-fns format
   */
  fnsFormat?: string;
  icon?: string;
  prefix?: string;
  suffix?: string;
  tag?: React.ElementType;
  time: Date | string;
  tooltip?: boolean;
}

const DateLine: FC<DateLineProps> = ({
  absoluteDates = false,
  fnsFormat,
  icon,
  time,
  prefix,
  suffix,
  tag: Tag = 'div',
  tooltip = false,
  className,
}: DateLineProps) => {
  if (!time) return null;

  let safeTime = new Date(time);
  let safeNow = new Date();

  let date;

  if (absoluteDates) {
    safeTime = new Date(safeTime.toDateString());
    safeNow = new Date(safeNow.toDateString());

    if (isToday(safeTime)) {
      date = 'Today';
    } else if (isYesterday(safeTime)) {
      date = '1 day ago';
    }
  }

  if (!date) {
    date = formatDistance(safeTime, safeNow, {
      addSuffix: true,
    });
  }

  if (fnsFormat) {
    date = format(safeTime, fnsFormat);
  }

  const tagElement = (
    <Tag className={`DateLine ${className || ''}`} suppressHydrationWarning>
      {!!icon && <i className={icon} />}
      {prefix} {date} {suffix}
    </Tag>
  );

  if (tooltip) {
    return <Tooltip content={format(safeTime, absoluteDates ? 'PPPP' : 'PPpp')}>{tagElement}</Tooltip>;
  }

  return tagElement;
};

export default React.memo(DateLine);
